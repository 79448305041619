////// MENU GAMBERGER ///////
#nav-icon2 {
  width: 37px;
  height: 29px;
  margin-left: auto;
  margin-right: 15px;
  position: relative;
  //margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 50%;
  background: #1bace4;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
  top: 11px;
}

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
  top: 22px;
}

a[aria-expanded="true"] #nav-icon2 span:nth-child(1), a[aria-expanded="true"] #nav-icon2 span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

a[aria-expanded="true"] #nav-icon2 span:nth-child(2), a[aria-expanded="true"] #nav-icon2 span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

a[aria-expanded="true"]  #nav-icon2 span:nth-child(1) {
  left: 3px;
  top: 7px;
}

a[aria-expanded="true"]  #nav-icon2 span:nth-child(2) {
  left: calc(50% - 3px);
  top: 7px;
}

a[aria-expanded="true"]  #nav-icon2 span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

a[aria-expanded="true"]  #nav-icon2 span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

a[aria-expanded="true"]  #nav-icon2 span:nth-child(5) {
  left: 3px;
  top: 20px;
}

a[aria-expanded="true"]  #nav-icon2 span:nth-child(6) {
  left: calc(50% - 3px);
  top: 20px;
}

////// END  MENU GAMBERGER ///////

@media screen and (max-width: 1180px) {
  .page-template-home-page .topic-text {
    padding-left: 15px;
  }

  //// menu header ////
  .header .top-bar-bottom .menu li a {
    font-size: 12px;
    padding: 18px 12px;
  }
  .top-bar {
    .title-site {
      font-size: 28px;
    }
    .top-bar-bottom .menu li a {
      padding: 15px 10px;
    }
    /// end menu header ///
    .top-menu .top-menu-item {
      padding: 0 8px;
      font-size: 13px;
      .fa {
        font-size: 18px;
      }
    }
    .social-links li {
      font-size: 18px;
    }
    .wpml-ls-legacy-list-horizontal {
      > ul {
        line-height: 1;
      }
      a {
        padding: 0 10px;
      }
    }

  }
  .team-user {
    .img-our-team-wrapper {
      height: 250px;
      img{
        width: auto;
        height: 100%;
      }
    }
    .text-our-team{
      height: 250px;
    }
  }
  .footer .menu li a {
    font-size: 14px;
  }
}

@media screen and (max-width: 1080px) {
  .header .top-bar-bottom .menu li a {
    padding: 18px 8px;
  }
}

@media screen and (max-width: 1023px) {

  .top-bar-top {
    .top-social-link {
      width: 100%;
      padding-top: 7px;
      text-align: center;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
    .social-links {
      width: auto;
      border-right: 2px solid #dcdcd9;
    }
    .top-language {
      width: auto;
      padding: 0 15px;
    }
  }

  .top-bar {
    .header-contact-info a {
      font-size: 16px;
    }
    .title-site {
      font-size: 26px;
    }
    .top-bar-bottom .menu li a {
      font-size: 12px;
      .submenu a {
        padding: 8px 10px;
      }
    }
    .top-register-subscrible {
      width: 100%;
      text-align: center;
      margin-bottom: 7px;
    }
  }
  //// end header top ///

  /// home page 1023 /////
  //////////////////

  ///**** Slick home ****///
  .page-template-home-page {
    .slider-section {
      .slide-title {
        font-size: 22px;
        text-align: left;
      }

    }

    .news-block {
      margin-bottom: 30px;
    }

    .about-us-section {
      .caption-block {
        font-size: 18px;
      }
      .title-block {
        font-size: 20px;
      }
    }
  }
  ///**** Slick home ****///
  .video-button-more {
    text-align: center;
  }

  .footer {
    .footer-contact {
      font-size: 16px;
    }
    .menu li {
      display: inline-block;
      a {
        font-size: 16px;
        padding: 10px;
      }
    }
  }

  .video-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  .page-template-association,
  .page-template-testing,
  .page-template-polygraps {
    .wrapper-polygraph-info {
      .image-pl-inf {
        text-align: center;
      }
      img {

        max-height: 200px;
        width: auto;
      }
      .description {

      }
    }
  }
  .page-template-polygraps {
    section.video-section {
      display: block;
    }
  }

  .diploms-image-box {
    display: block;
    width: 80%;
    margin: auto;
  }
  section.mass-media-home-page a.mass-media-button{
    width:auto;
  }
  .page-template-association .expert-item .expert-item-image img,
  .page-template-testing .expert-item .expert-item-image img,
  .page-template-polygraps .expert-item .expert-item-image img{
    height: auto;
  }
  .page-template-home-page {
    .video-block {
      width: 50%;
    }
  }
  section.convenants .convenants-item{
    flex-wrap: wrap;
  }
  .single-post .article-footer .other-links-block{
    padding: 0 15px;
  }

  ///// quick order form max-width 1023px////
  span.question-icon{
    display: none;
  }
  ///// end quick order form max-width 1023px////
}

/*  */
@media screen and (max-width: 980px){
  ///// quick order form max-width 980px////
  .quick-form-wrapper-box {
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    .quick-form-text{
      margin: 0 0 18px;
      width: 100%;
      text-align: center;
    }
    .quick-form-box {
      position: relative;
      width: 60%;
    }
    span.question-icon{
      display: block;
      width: auto;
      margin: 0 0 10px;
    }
  }
  ///// end quick order form max-width 980px////

}
////// @media screen and (max-width: 767px)
@media screen and (max-width: 767px) {

  .hide-for-mob {
    display: none !important;
  }
  .show-for-mob {
    display: block !important;
  }

  #content #inner-content {
    padding: 1rem 0 0;
  }
  .add-bg-menu{
    .menu-gamburger{
      position: fixed;
      top: 10px;
      right: 10px;
      z-index: 1;
      margin: 0;
      background: #1bace4;
      border-radius: 50%;
      padding: 11px 10px;
      height: inherit;
    }
    #nav-icon2 {
      width: 20px;
      height: 18px;
      span{
        background: #ffffff;
        &:nth-child(3),
        &:nth-child(4) {
          top: 7px;
        }

        &:nth-child(5),
        &:nth-child(6) {
          top: 14px;
        }
      }
    }

  }

  ///// header menu and logo 767px ////

  .contact-header.visib-mob {

    .visible-contect {
      display: block;
    }
  }
  .logo-header img {
    width: 100%;
    height: auto;
  }
  #nav-icon2{
    margin-right: 0;
  }
  .menu-gamburger {
    height: 30px;
    margin-bottom: 15px;
  }
  .contact-header {
    height: 40px;
    position: relative;
    .visible-contect {
      display: none;
      position: absolute;
      top: 45px;
      border: 1px solid #e0e0e0;
      right: 0;
      max-width: 480px;
      width: 200px;
      text-align: center;
      height: auto;
      padding: 15px;
      background: #fff;
      &:before {
        z-index: 999;
        top: -20px;
      }
      &:after {
        z-index: 998;
        top: -21px;
        border-bottom-color: #e2e2e2 !important;
      }
      &:before,
      &:after {
        content: '';
        right: 22px;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border-left: 10px solid transparent;
        border-top: 10px solid transparent;
      }
    }
    .show-phone-header {
      position: absolute;
      width: 35px;
      height: 35px;
      right: 0px;
      border: 1px solid #1bace4;
      border-radius: 50%;
      text-align: center;
      color: #1bace4;
      font-size: 18px;
      line-height: 35px;
    }
  }
  .top-bar {
    .top-bar-center {
      border-bottom: 1px solid rgba(204, 204, 204, 0.5);

    }
    .title-site {
      font-size: 22px;
    }
  }

  .menu {
    a:link,
    a:visited:link {
      -webkit-tap-highlight-color: rgba(255, 0, 0, 0);
      //color: #fc0;
    }
    a:hover{
      color: #fc0;
    }
    > li {
      > a {
        text-transform: uppercase;
        text-align: left;
        padding-left: 15px;
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);

      }
      .submenu li a {
        text-transform: none;
        padding-left: 25px;
        padding-right: 10px;
      }
    }
    .active > a {
      background: none;
      color: #fc0;
    }
  }
  .is-accordion-submenu-parent > a::after {
    border-color: #ffffff transparent transparent;
  }
  ///// end header menu and logo 767px ////
  .page-template-home-page {
    .text-section{
      display: none;
    }
    .topic-text span {
      width: 34px;
      height: 34px;
      font-size: 16px;
      line-height: 32px;
    }
    .slider-section {
      .topic-text{
        padding: 10px 0;
      }
      .slide-title {
        font-size: 18px;
        a{
          text-align: center;

        }
        p{
          font-size: 18px;
        }
      }
      .description {
        font-size: 16px;
        text-align: center;
      }
    }
    .services-section .services {
      margin: 40px 0 10px;
    }
    .hr-line {
      padding-top: 0;
    }
  }
  .news-block {
    .topic-text {
      padding-bottom: 20px;
    }
    .button-more{
      margin-right: 15px;
    }
  }

  .footer .menu li a {
    text-align: center;
    border-bottom: none;
    font-size: 14px;
    padding: 10px 5px;
  }
  .img-block a img {
    width: auto;
    max-width: inherit;
    height: 100%;
  }

  /// 767 contact page /////
  .contact-box-wrapper {
    width: 80%;
    margin-top: -75px;
    padding: 20px;
    .adress-email-phone-box {
      padding-left: 50px;
      margin-bottom: 15px;
      padding-bottom: 10px;

      .icon-contac-fa {
        font-size: 20px;
        width: 40px;
        height: 40px;
        .fa-envelope {
          font-size: 18px;
        }
      }
      a {
        font-size: 16px;
      }
    }
    .social-box-contact ul li a {
      padding: 5px 10px;
      font-size: 22px;
    }
  }
  .page-template-contacts {
    .page-title {
      font-size: 22px;
      line-height: 1.2;
    }
    .payment-info {
      font-size: 16px;
    }
    .acf-map {
      height: 400px;
    }
  }
  .team-user p.name-our-team {
    font-size: 16px;
  }

  /// end 767 contact page /////
  section.mass-media-home-page .mass-media-name h3{
    font-size: 14px;
  }
  section.diploms-home-page{
    height: auto;
  }
  section.quick-order-form {
    padding: 0 0 40px;
  }
  section.number-about-us {
    display: none;
  }

  section.mass-media-home-page .btn-all-mass-media{
    text-align: right;
  }
  .content-box-training-section h5 {
    line-height: 1.4;
    font-size: 1.1875rem;
  }

  .testing-sc-23{
    .seo_schema_org{
      max-height: 70vh;
      overflow-y: scroll;
      border: 1px solid rgba(27, 172, 228, 0.5);
      padding: 5px;
    }
  }

}


////// END @media screen and (max-width: 767px)

////// @media screen and (max-width: 640px)
@media screen and (max-width: 640px) {
  .top-bar {
    .title-site {
      font-size: 18px;
    }
  }
  .registry-text-section {
    text-align: center;
    ul{
      text-align: left;
    }
  }

  .page-template-home-page {
    .about-us-section {
      .more-btn {
        //margin-top: 40px;
        float: right;
      }
      .left-block {
        margin-bottom: 35px;
      }
      .about-us-text{
        margin-bottom: 15px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }

  .pagination li{
    display:inline-block;
  }

  .page-template-home-page {
    .video-block {
      width: 50%;
    }
    .topic-text {
      font-size: 16px;
    }
    .title-home {
      font-size: 18px;
    }
    .slider-section .slide-title p{
      font-size: 16px;
    }
  }
  .archive .title-post, .news-section .title-post {
    font-size: 16px;
  }

  .page-template-home-page .about-us-section .description-block,
  .page-template-home-page .about-us-section .description {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
  h2.header-registry-section {
    font-size: 21px;
  }
  .footer {
    .menu{
      border-bottom: 1px solid #fff;
      padding-bottom: 5px;
      //margin-bottom: 10px;
    }
    .logo{
      padding-top: 0;
    }
    .social-links{
      //border-top: 1px solid #fff;
      //border-bottom: 1px solid #fff;
      margin-bottom: 10px;
    }


    #menu-footer-menu {
      &:before,
      &:after {
        display: table;
        content: ' ';
      }
      &:after {
        clear: both;
      }
      li {
        display: block;
        width: 50%;
        float: left;
      }
    }
    ul.subscrible-menu {
      text-align: center;
      border-bottom: 1px solid #fff;
      padding-bottom: 5px;
      margin-bottom: 10px;
      li.subscrible-menu-item {
        padding-left: 0;
        .fa {
          position: relative;
          left: 0px;
          top: 0px;
          padding-right: 10px;
        }
      }
    }
    .footer-contact {
      text-align: center;
      .fa {
        position: relative;
        top: 0;
        display: inline-block;
        padding-right: 10px;
      }
      a {
        padding-left: 0;
        span.text-info {
          display: inline;
        }
      }
    }
  }
  .map-block {
    .info-region-image {
      width: 30%;
      img {
        max-height: 150px;
        width: auto;
        margin: auto;
        display: block;
      }
    }
    .info-region-description{
      width:70%;
    }
  }
  .loop-archive-wrapper .loop-archive-left-content a {
    margin-bottom: 20px;
    img {
      max-height: 250px;
      /* width: auto; */
      /* max-width: none; */
      margin: auto;
      display: block;
    }
  }
  .single-post .article-footer .link-other{
    font-size: 14px;
  }
  ///// quick order form max-width 640px////
  .quick-form-wrapper-box {
    width: 100%;
    padding: 19px 20px 21px;
    .quick-form-text{
      p{
      font-size: 1.111rem;
    }
    }
    .quick-form-box {
      width: 100%;
    }
    span.question-icon{
      display: block;
      width: auto;
      margin: 0 0 10px;
    }
    .gform_fields .gfield input{
      width:100%;
    }
    .gform_footer {
      position: relative;
      margin-top: 25px;
    }
  }
  ///// end quick order form max-width 640px////
  .page-template-home-page .partners-block .partners-item img{
    max-width: 120px;
  }
  .page-template-association,
  .page-template-polygraps,
  .page-template-testing {
    .expert-item:last-child {
      padding-bottom: 0;
    }
  }
  .page-template-testing{
    .title-section-h3-tts{
      p{
        font-size: 1.1875rem;
      }

    }
    h3.title-block,
    .title-block{
      font-size: 1.1875rem;
    }
    .title-block{
      padding: 20px 0;
    }
    .question-answer h3.title-block-question-alb{
      margin-bottom: 20px;
      font-size: 1.1875rem;
    }
    span.decor-pad {
      padding-bottom: 20px;
    }
  }

}

////// End @media screen and (max-width: 640px)

////// @media screen and (max-width: 480px)
@media screen and (max-width: 480px) {

  p {
    font-size: 14px;
  }

  //////////////// HEADER TITLE PAGE AND IMG ///////////////////

  .title-head h1,
  .page-title-association,
  .page-title {
    font-size: 22px;
    line-height: 1.2;
  }
  span.image-under-title {
    img {
      max-height: 120px;
      width: auto;
    }
  }
  .page-template-contacts {
    .page-title {
      font-size: 22px;
      line-height: 1.2;
    }
    .payment-info {
      font-size: 18px;
    }
  }
  .single-post .single-title {
    font-size: 18px;
  }
  .title-gallery {
    font-size: 18px;
    line-height: 1.2;
  }
  ////////////// END HEADER TITLE PAGE AND IMG ////////////////

  .top-bar {
    .logo-header {
      width: 50%;
    }
    //.title-site {
    //  font-size: 14px;
    //}
  }
  .breadcrumbs {
    font-size: 14px;
    text-align: left;
  }

  .page-template-home-page {
    .news-section .news-block .button-more{
      margin-right: .625rem;
    }
    .news-block {

      .post{
        height: auto;
      }
      .home-news-block-img {
        width: 100%;
      }
      .home-news-block-content {
        width: 100%;
      }
    }
    .video-block {
      width: 100%;
      height: auto;
      margin-bottom: 0;
      .video-link{
        max-height: 300px;
        width: 100%;
        height: auto;
        img{
          max-width: 100%;
          width: 100%;
          height: inherit;
          transform: inherit;
          position: relative;
          top: inherit;
          Left: inherit;
        }
      }
      .video-title{
        text-align: center;
      }
    }
  }
  .testimonials-home-page{
    .slick-prev {
      left: -10px;
    }
    .slick-next {
      right: -10px;
    }
  }
  .page-template-home-page {
    .news-section {
      .home-news-block-img {
        padding: 0;
        margin-bottom: 8px;
        a{
          height: inherit;
          img{
            max-width: 100%;
            width: 100%;
            height: inherit;
            transform: inherit;
            position: relative;
            top: inherit;
            Left: inherit;
          }
        }
      }
    }
  }
  .page-template-association,
  .page-template-polygraps {
    h3.title-block,
    .title-block {
      text-align: left;
      font-size: 16px;
    }
  }
  .page-template-association,
  .page-template-testing,
  .page-template-polygraps {
    .table-section {
      .right-column {
        font-size: 14px;
        word-break: break-word;
      }
      .left-column {
        font-size: 14px;
      }
    }
    .block-with-accordion {
      .accordion-item {
        .accordion-title {
          font-size: 14px;
          padding: 10px 25px;
          &:before,
          &:after {
            width: 11px;
            height: 1px;
            top: 45%;
          }
        }
      }
    }

    .text-section .text-block {
      font-size: 14px;
    }
    .link-other {
      padding: 7px;
      font-size: 14px;
      line-height: 1.2;
    }
    .expert-item {
      .expert-item-image {
        text-align: center;
      }
      .full-name {
        text-align: center;

      }
      .position {
        text-align: left;
        font-size: 14px;
      }

    }
    .expert-item-image-bx{
      height: 230px;
      overflow: hidden;
      position: relative;
      text-align: center;
      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .page-template-question .question-section .accordion-item .accordion-title {
    font-size: 14px;
    padding: 10px 25px;
    text-align: left;
    &:before,
    &:after {
      width: 11px;
      height: 1px;
    }
  }

  .map-block {
    .info-region-description {
      width:100%;
      p {
        text-align: left;
        font-size: 16px;
      }
      a {
        text-align: left;
        display: block;
        font-size: 14px
      }
    }
    .info-region-image {
      width:100%;
      img {
        max-height: 100px;
        width: auto;
        margin: auto;
        display: block;
      }
    }

  }
  .title-head-training {
    flex-wrap: wrap;
  }
  .image-under-title-3 {
    width: 100%;
    text-align: center;
  }
  .page-title-3 {
    width: 100%;
  }
  .img-block {
    width: 50%;
    height: 150px;
    a img {
      max-width: inherit;
      height: 100%;
      width: auto;
    }
    .gal-hover-wrap {
      padding: 5px;
      p {
        font-size: 14px;
      }
    }
  }
  .button-box-wrap .link-other {
    padding: 7px;
    margin: 0 0 7px;
    font-size: 14px;
  }
  .content-text-box {
    h4 {
      font-size: 18px;
    }
    p, li {
      font-size: 14px;
    }
  }
  section.content-only-text .title-block {
    font-size: 18px;
  }


  ////// 480 contact page /////
  .page-template-contacts {

    h3.title-block-convenants,
    .title-block {
      font-size: 16px;
      line-height: 1.2;
      span {
        margin-right: 5px;
        margin-bottom: 5px;
      }

    }
    section.text-contact {
      padding-top: 20px;
    }
    section.map-block {
      padding-top: 30px;
      padding-bottom: 40px;
    }
  }
  .contact-box-wrapper {
    width: 100%;
    margin-top: 0;
  }
  section.convenants {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  ////// end 480 contact page /////

  /// footer ...../////
  .footer {
    .menu{
      border-bottom: 1px solid #fff;
      padding-bottom: 5px;
      //margin-bottom: 10px;
    }
    .logo{
      padding-top: 0;
    }
    .social-links{
      margin-bottom: 10px;
    }


    #menu-footer-menu {
      &:before,
      &:after {
        display: table;
        content: ' ';
      }
      &:after {
        clear: both;
      }
      li {
        display: block;
        width: 50%;
        float: left;
      }
    }
    ul.subscrible-menu {
      text-align: center;
      border-bottom: 1px solid #fff;
      padding-bottom: 5px;
      margin-bottom: 10px;
      li.subscrible-menu-item {
        padding-left: 0;
        .fa {
          position: relative;
          left: 0px;
          top: 0px;
          padding-right: 10px;
        }
      }
    }
    .footer-contact {
      text-align: center;
      .fa {
        position: relative;
        top: 0;
        display: inline-block;
        padding-right: 10px;
      }
      a {
        padding-left: 0;
        span.text-info {
          display: inline;
        }
      }
    }
  }

  /// end  footer ...../////
  .dataTables_filter,
  .dataTables_length{
    width:100%;
  }


}
