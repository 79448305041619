.page-template-contacts{
  section.title-contact-box{
    padding-bottom: 0;
  }
  .page-title{
   font-size: 27px;
  }
  .payment-info{
    color: #fc0;
    text-align: center;
    font-size: 20px;
  }
  .title-block{
    padding-left: 1px;
    padding-bottom: 5px;
    font-size: 18px;
    line-height: 40px;
    color: #333;
    text-transform: uppercase;
    letter-spacing: .1em;
    text-align: left;
    span{
      font-style: italic;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      top: 2px;
      width: 40px;
      height: 40px;
      color: #fc0;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      border: 2px solid #fc0;
      border-radius: 50%;
      margin-right: 15px;
    }
  }
  .description{
    font-size: 16px;
  }
  section{
    a{
      color: #1bace4;
      font-size: 18px;
      &:hover{
        color: #fc0;
      }
    }
  }
  h3.title-block-convenants{
    padding-left: 1px;
    padding-bottom: 5px;
    font-size: 18px;
    line-height: 40px;
    color: #333;
    text-transform: uppercase;
    letter-spacing: .1em;
    text-align: left;
    span{
      font-style: italic;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      top: 2px;
      width: 40px;
      height: 40px;
      color: #fc0;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      border: 2px solid #fc0;
      border-radius: 50%;
      margin-right: 15px;
    }
  }
  section.text-contact{
    padding-top: 80px;
  }
}
////// new contact ////
.contact-box-wrapper {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.35);
  width: 60%;
  margin: -120px auto 0;
  float: none;
  border-radius: 5px;
  padding: 30px;
  z-index: 11;
  position: relative;
  background: white;
  > div:last-child{
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  a{
    font-size: 16px;
    margin-bottom: 5px;
  }
  .email-wrapper-box.adress-email-phone-box{
    .icon-contac-fa{
      padding-top: 4px;
    }
  }
  .adress-email-phone-box {
    padding-left: 75px;
    position: relative;
    border-bottom: 1px dashed gainsboro;
    margin-bottom: 20px;
    padding-bottom: 15px;
    p{
      margin-bottom: 5px;
      font-size: 14px;
    }
    .icon-contac-fa{
      position: absolute;
      left: 0;
      top: 3px;
      font-size: 25px;
      color: #ffffff;
      width: 50px;
      background: #1bace4;
      height: 50px;
      border-radius: 50%;
      text-align: center;
      .fa{
        position: absolute;
        top: 50%;
        Left: 50%;
        transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        line-height: 1;
      }
      .fa-envelope{
        font-size: 22px;
      }
    }
    h3 {
      font-size: 16px;
      font-weight: 600;
    }
    ul{
      list-style-type: none;
      margin: 0;
      li{
        margin-bottom: 5px;
      }
    }

  }
  .description-pshys-address {
    font-size: 14px;
  }
  .social-box-contact{
    border-bottom: 1px dashed gainsboro;
    margin-bottom: 20px;
    padding-bottom: 15px;
    h3.title-social-contact {
      font-size: 18px;
    }
    ul{
      list-style-type: none;
      margin: 0;
      li{
        display: inline-block;
        a{
          padding: 5px 20px;
          font-size: 28px;
        }
      }
    }
  }
}
////// Our Team ////
section.convenants {
  padding-top: 60px;
  //padding-bottom: 50px;
  .convenants-item{
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    &> div{
      max-width: 380px;
    }
  }
}
.team-user {
  position: relative;
  width: 100%;
  margin: 15px auto;
  overflow: hidden;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  &:hover{
    .text-our-team{
      opacity: 1;
    }
  }
  .img-our-team-wrapper{
    overflow: hidden;
    height: 300px;
    margin: auto;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    img{
      margin: auto;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      -o-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
    }
  }
  p.name-our-team {
    display: block;
    margin: 0;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    padding: 10px;
    z-index: 1;
    position: relative;
    background: #1bace4;
  }
  .text-our-team {
    display: block;
    opacity: 0;
    background: rgba(0, 0, 0, 0.6);
    text-align: center;
    position: absolute;
    width: 100%;
    height: 300px;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    > div{
      width: 100%;
      padding: 20px 15px;
      position: absolute;
      top: 50%;
      Left: 50%;
      transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
    .user-contect-link{
      display: block;
      font-size:16px;
      margin-bottom: 5px;
    }
  }
  .position,
  .description{
    font-size: 16px;
    color:#fff;
  }
  .position_2{
    font-size: 14px;
    color:#fff;
  }

}

section.map-block{
  padding-top: 80px;
  padding-bottom: 50px;
}