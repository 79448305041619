/******************************************************************

Stylesheet: Print Stylesheet

This is the print stylesheet. There's probably not a lot
of reasons to edit this stylesheet. 

Remember to add things that won't make sense to print at 
the bottom. Things like nav, ads, and forms shouldbe set 
to display none.
******************************************************************/
@media print {

  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    filter:none !important;
    -ms-filter: none !important;
  }

  a, a:visited {
    color: #444 !important;
    text-decoration: underline;

    // show links on printed pages
    &:after {
      content: " (" attr(href) ")";
    }

    // show title too
    abbr[title]:after {
      content: " (" attr(title) ")";
    }
  }

  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

    @page {
      margin: 0.5cm;
    }

    p, h2, h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }

    // Hide content people who print don't need to see
    .sidebar,
    .page-navigation,
    .wp-prev-next,
    .respond-form,
    nav {
      display: none;
    }
    
} // End of media query