/************************************************

Stylesheet: Main Stylesheet

*************************************************/
//@font-face {
//  font-family: "FontAwesome";
//  font-weight: normal;
//  font-style : normal;
//  src : url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.woff2?v=4.7.0");
//  src : url("https://cdn.jsdelivr.net/fontawesome/4.7.0/fonts/fontawesome-webfont.woff2?v=4.7.0");
//  font-display: swap;
//}
//@font-face {
//  font-family: "roboto";
//  font-weight: normal;
//  font-style : normal;
//  src : url("https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fBBc9AMP6lQ.ttf");
//  src : url("https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxPKTU1Kg.ttf");
//  font-display: swap;
//}
/*********************
GENERAL STYLES
*********************/
.hide-desc {
  display: none;
}

.block-centered {
  margin: auto;
}

/*********************
LINK STYLES
*********************/
a {
  color: #1bace4;
  &:hover {
    color: #fc0;
  }
}

a, a:visited {

  // Mobile tap color
  &:link {
    //Highlight links on iOS, Similar to :hover for mobile devices.
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
  }
}

body {
  font-family: Georgia;
  font-size: 16px;
  text-align: justify;
}

p.wp-caption-text {
  font-style: italic;
}

div.aligncenter {
  text-align: center;
  margin: auto;
}

img.aligncenter {
  text-align: center;
  display: block;
  margin: auto;
}

img.alignright {
  float: right;
  text-align: right;
  margin: 0 0 0 15px;
}

img.alignleft {
  float: left;
  text-align: left;
  margin: 0 15px 0 0;
}
/*********************
H1, H2, H3, H4, H5 P STYLES
*********************/
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  font-family: Georgia, Helvetica, Roboto, Arial, sans-serif;
  // Removing text decoration from all headline links
  a {
    text-decoration: none;
  }
}
/*********************
HEADER STYLES
*********************/
.header {
  ul.off-canvas-list li {
    list-style: none;
  }
}
.off-canvas {
  background: #1bace4;
  z-index:3;
}
header.header {
  &.add-bg-menu {
    .top-bar-bottom{
      position: fixed;
      top: -100px;
      left: 0;
      z-index: 99991;
      width: 100%;
      background: #fff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
    }
    &.header-hide {
      .top-bar-bottom{
        top:0;
      }
    }
  }

}

/*********************
NAVIGATION STYLES
*********************/

.top-bar {
  ul {
    background: transparent;
  }
  background: transparent;
  max-width: 1135px;
  padding: 0;
  margin: 0 auto;
  .top-bar-top {
    padding: 7px 0;
    border: 1px solid rgba(204, 204, 204, .5);
    border-top: none;
    border-radius: 0 0 5px 5px;
    div {
      padding: 0;
    }
  }

  .logo-header {
    width: 20%;
  }
  .header-contact-info {
    text-align: right;
    //width:auto;
    width: 20%;
    position: relative;
    a {
      display: block;
      color: #000;
      font-size: 20px;
      &:hover {
        color: #1bace4;
      }
    }
    .text {
      color: #1bace4;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .top-bar-center {
    padding: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  .title-site {
    color: #1bace4;
    //padding-top: 16px;
    //font-size: 2.4em;
    font-size: 34px;
    text-align: center;
  }
  .wpml-ls-item-ru{
    a img{
      border: 1px solid #9e9e9e;
    }
  }
  .wpml-ls-legacy-list-horizontal > ul {
    background: transparent;
    text-align: right;
  }
  .social-links {
    margin: 0;
    list-style-type: none;
    background: transparent;
    text-align: center;

    li {
      display: inline-block;
      font-size: 22px;
      line-height: 1;
      margin: 0 5px;
      padding-top: 2px;
      a {
        color: #fc0;
        &:hover {
          color: #1bace4;
        }
      }
    }
  }
  .title-area {
    z-index: 1;
  }
}
.header {
  .top-bar-bottom {
    padding: 0;
    &:before, &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: #e5e5e5;
      position: absolute;
      left: 0;
      z-index: 1;
    }
    .menu {
      text-align: center;
      .active {
        a {
          background: transparent;
        }
      }
      li {
        display: inline-block;
        a {
          font-weight: bold;
          font-size: 14px;
          text-transform: uppercase;
          color: #0a0a0a;
          padding: 18px;
          &:hover {
            color: #1bace4;
          }
        }
      }
      li.menu-item-has-children {
        a {
          &:after {
            display: none;
          }
          &:before {
            position: absolute;
            right: 5px;
            content: '\f107';
            font-family: FontAwesome;
          }
        }
        .submenu {
          background: #1bace4;
          text-align: left;
          min-width: 325px;
          li {
            a {
              color: #fff;
              font-size: 12px;
              line-height: 1.4;
              padding: 10px 15px;
              &:before {
                display: none
              }
              &:hover {
                color: #fc0;
              }
            }
          }
        }
      }
    }
  }
  .top-menu {
    background: transparent;
    list-style-type: none;
    margin: 0;
    .top-menu-item {
      display: inline-block;
      cursor: pointer;
      color: #000;
      border-right: 2px solid #dcdcd9;
      padding: 0 15px;
      font-size: 14px;
      outline: none;
      &:last-child {
        border-right: 0;
      }
      .fa {
        font-size: 22px;
        margin-right: 10px;
        color: #fc0;
      }
      &:hover {
        color: #1bace4;
        .fa {
          color: #1bace4;
        }
      }
    }
  }
}

.off-canvas-list {
  ul {
    margin-left: 0;
    li {
      a {
        border-bottom: 0px;
      }
    }
    .dropdown {
      margin-left: 20px;
    }
  }
}

/*********************
POSTS & CONTENT STYLES
*********************/
#content {
  #inner-content {
    padding: 2rem 0 0;
  }
}

.single-title,
.page-title {

}

.single-title {

}

.page-title {

  // Remove Foundation vcard styling from author page titles
  .vcard {
    border: 0px;
    padding: 0px;
  }
}

.archive-title {

}

// Post meta  
.byline {
  color: #999;
  time {
  }
}

// Entry content  
.entry-content {

  img {
    max-width: 100%;
    height: auto;
  }

  .alignleft, img.alignleft {
    margin-right: 1.5em;
    display: inline;
    float: left;
  }
  .alignright, img.alignright {
    margin-left: 1.5em;
    display: inline;
    float: right;
  }
  .aligncenter, img.aligncenter {
    margin-right: auto;
    margin-left: auto;
    display: block;
    clear: both;
  }

  video, object {
    max-width: 100%;
    height: auto;
  }

  pre {
    background: #eee;
    border: 1px solid #cecece;
    padding: 10px;
  }

}

.wp-caption {
  max-width: 100%;
  background: #eee;
  padding: 5px;

  // Images inside wp-caption
  //img {
  //  max-width: 100%;
  //  margin-bottom: 0;
  //  width: 100%;
  //}

  p.wp-caption-text {
    font-size: 0.85em;
    margin: 4px 0 7px;
    text-align: center;
  }
}

// Add Foundation styling to password protect button
.post-password-form input[type="submit"] {
  @include button;
}

/*********************
IMAGE GALLERY STYLES
*********************/
.gallery {

  dl {

    a {
    }

    img {
    }

  }

  dt {
  }

  dd {
  }

}

// Gallery caption styles
.gallery-caption {
}

.size-full {
}

.size-large {
}

.size-medium {
}

.size-thumbnail {
}

/*********************
PAGE NAVI STYLES
*********************/
.page-navigation {
  margin-top: 1rem;
}

/*********************
COMMENT STYLES
*********************/
#comments {
  .commentlist {
    margin-left: 0px;
  }
}

#respond {
  ul {
    margin-left: 0px;
  }
}

.commentlist {
  li {
    position: relative;
    clear: both;
    overflow: hidden;
    list-style-type: none;
    margin-bottom: 1.5em;
    padding: 0.7335em 10px;

    &:last-child {
      margin-bottom: 0;
    }

    ul.children {
      margin: 0;

      li {
      }

      .comment {
      }
      .alt {
      }
      .odd {
      }
      .even {
      }
      .depth-1 {
      }
      // Change number for different depth
      .byuser {
      }
      .bypostauthor {
      }
      .comment-author-admin {
      }

    }
  }

  li[class*=depth-] {
    margin-top: 1.1em;
  }

  li.depth-1 {
    margin-left: 0;
    margin-top: 0;
  }

  li:not(.depth-1) {
    margin-left: 10px;
    margin-top: 0;
    padding-bottom: 0;
  }

  li.depth-2 {
  }
  li.depth-3 {
  }
  li.depth-4 {
  }
  li.depth-5 {
  }

  // General comment classes
  .alt {
  }
  .odd {
  }
  .even {
  }
  .parent {
  }
  .comment {
  }
  .children {
  }
  .pingback {
  }
  .bypostauthor {
  }
  .comment-author {
  }
  .comment-author-admin {
  }
  .thread-alt {
  }
  .thread-odd {
  }
  .thread-even {
  }

  // vcard
  .vcard {
    margin-left: 50px;

    cite.fn {
      font-weight: 700;
      font-style: normal;

      a.url {
      }

    }

    time {
      float: right;

      a {
        color: #999;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

      }

    }

    img.photo {
    }

    img.avatar {
      position: absolute;
      left: 10px;
      padding: 2px;
      border: 1px solid #cecece;
      background: #fff;
    }

  }
  // end .commentlist .vcard

  // comment meta
  .comment-meta {

    a {
    }

  }

  .commentmetadata {

    a {
    }

  }

  // comment content
  .comment_content {

    p {
      margin: 0.7335em 0 1.5em;
      font-size: 1em;
      line-height: 1.5em;
    }

  }
  // end .commentlist .comment_content

  // comment reply link
  .comment-reply-link {
    float: right;

    &:hover,
    &:focus {

    }

  }
  // end .commentlist .comment-reply-link

}

// end .commentlist

/*********************
COMMENT FORM STYLES
*********************/
.respond-form {
  margin: 1.5em 10px;
  padding-bottom: 2.2em;

  form {
    margin: 0.75em 0;

    li {
      list-style-type: none;
      clear: both;
      margin-bottom: 0.7335em;

      label,
      small {
        display: none;
      }
    }

    input[type=text],
    input[type=email],
    input[type=url],
    textarea {
      padding: 3px 6px;
      background: #efefef;
      border: 2px solid #cecece;
      line-height: 1.5em;

      &:focus {
        background: #fff;
      }

      // form validation
      &:invalid {
        outline: none;
        border-color: #fbc2c4;
        background-color: #f6e7eb;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -ms-box-shadow: none;
        box-shadow: none;
      }
    }

    input[type=text],
    input[type=email],
    input[type=url] {
      max-width: 400px;
      min-width: 250px;
    }

    textarea {
      resize: none;
      width: 97.3%;
      height: 150px;
    }

  }
}

// comment submit button  
#submit {
}

// comment form title  
#comment-form-title {
  margin: 0 0 1.1em;
}

// cancel comment reply link  
#cancel-comment-reply {
  a {
  }
}

// logged in comments  
.comments-logged-in-as {
}

// allowed tags  
#allowed_tags {
  margin: 1.5em 10px 0.7335em 0;
}

// No comments  
.nocomments {
  margin: 0 20px 1.1em;
}

/*********************
SIDEBARS & ASIDES
*********************/
.sidebar {
}

#searchsubmit {
}

.widgettitle {
}

.widget {
  ul {
    margin: 0;

    li {
      list-style: none;

      &:first-child {
      }

      &:last-child {
      }

      a {
      }

      // deep nesting
      ul {
      }

    }
  }
}

/*********************
FOOTER STYLES
*********************/
.footer {
  clear: both;
  margin-top: 1em;
  background: #1bace4;
  text-align: center;
  padding: 10px 0;
  #menu-footer-menu {
    border-bottom: 1px solid #e5e5e5;
  }
  .center-footer {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    .left-footer-box {
      text-align: center;
    }
  }
  .logo {
    padding: 10px 0;
    //text-align: left;
  }
  ul.subscrible-menu {
    list-style-type: none;
    text-align: left;
    color: #fff;
    font-size: 18px;
    margin: 0;
    li.subscrible-menu-item {
      padding-bottom: 6px;
      cursor: pointer;
      padding-left: 25px;
      position: relative;
      &:hover {
        color: #fc0;
      }
      a {
        color: #fff;
        padding-left: 25px;
        position: relative;
        &:hover {
          color: #fc0;
        }
      }
      .fa {
        position: absolute;
        left: 0px;
        top: 0px;

      }
    }
  }

  .menu {
    li {
      &:first-child {
        a {
          padding-left: 0;
        }
      }
      &:last-child {
        a {
          padding-right: 0;
        }
      }
      a {
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
        &:hover {
          color: #fc0;
        }

      }
    }
    .active > a {
      color: #fc0;
      background: none;
    }
  }
  .social-links {
    list-style-type: none;
    margin: 0;
    //text-align: left;
    li {
      display: inline-block;
      margin: 0 10px;
      a {
        color: #fff;
        font-size: 27px;
        &:hover {
          color: #fc0;
        }
      }
    }
  }
  .copyright-block {
    padding: 15px 0;
    color: #fff;
    border-top: 1px solid #e5e5e5;
    p {
      margin: 0;
      text-transform: uppercase;
      font-size: 14px;
    }
  }
  .footer-contact {
    display: block;
    font-size: 20px;
    color: #fff;
    text-align: left;
    a {
      color: #fff;
      padding-left: 25px;
      position: relative;
      display: block;

      &:hover {
        color: #fc0;
      }
      span.text-info {
        padding-left: 0;
        transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        &:hover {
          color: #fc0;
        }
      }
    }
    span.text-info {
      color: #fff;
      padding-left: 25px;
      position: relative;
      display: block;
    }
    .fa {
      position: absolute;
      top: 8px;
      left: 0;
    }
  }
}

.breadcrumbs {
  a {
    color: #1bace4;
  }
  padding: 0 15px;
}

.row {
  max-width: 1135px;
}

/*********************
FOUNDATION STYLES
*********************/

/*********************
PLUGIN STYLES
*********************/
// Makes Gravity Forms play nice with Foundation Form styles
.gform_body ul {
  list-style: none outside none;
  margin: 0;
}

hr {
  border-bottom: 1px solid rgba(204, 204, 204, .5);
}

.single-post {
  p {
    margin-bottom: 10px;
    text-align: justify;
    letter-spacing: 0.06em;
  }
  #main {
    padding: 0;
  }
  .single-title {
    font-size: 24px;
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid rgba(204, 204, 204, .5);
    border-bottom: 1px solid rgba(204, 204, 204, .5);
    //font-size: 34px;
    color: #1bace4;
    text-transform: uppercase;
  }
  .image-post {
    text-align: center;
    border-bottom: 1px solid rgba(204, 204, 204, .5);
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .post-content {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    text-align: justify;
  }
  .article-footer {
    border-top: 1px solid rgba(204, 204, 204, .5);
    padding-top: 15px;
    div {
      padding: 0;
    }
    .title-block {
      font-size: 21px;
      color: #fc0;
    }
    .content {
      color: #000;
      font-size: 16px;
    }
    .image-bottom {
      text-align: center;
    }
    .other-links-block {
      margin: 15px 0;
      //border-top: 1px solid rgba(204,204,204,.5) ;
      //padding-top: 15px;
    }
    .link-other {
      display: block;
      padding: 9.5px;
      margin: 0 0 10px;
      line-height: 1.42857143;
      word-break: break-all;
      word-wrap: break-word;
      background-color: #f5f5f5;
      border: 1px solid #ccc;
      border-radius: 4px;
      color: #1bace4;
      font-size: 16px;
      &:hover {
        color: #fc0;
      }
      .fa {
        margin-right: 5px;
      }
    }
  }
  .wp-caption {
    background: transparent;
  }
}

.post-title-block {
  margin-bottom: 15px;
}

.page-title {
  font-size: 32px;
  text-align: center;
  padding: 10px 0;
  color: #1bace4;
  border-top: 1px solid rgba(204, 204, 204, 0.5);
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
}

.loop-archive-wrapper {
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
  .loop-archive-left-content {
    a {
      display: block;
      position: relative;
      max-height: 250px;
      overflow: hidden;
    }
  }

  .loop-archive-right-content {
    .date {
      border-left: 2px solid #fc0;
      padding-left: 10px;
      //margin-bottom: 10px;
    }
    .title-post {
      text-align: left;
    }

  }
}

.more-btn {
  color: #fff;
  background-color: #1bace4;
  display: block;
  float: right;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #1bace4;
  border-radius: 4px;
  &:hover {
    background: #fc0;
    border-color: #fc0;
    color: #fff;
  }
}

.archive, .news-section {

  .title-post {
    font-size: 20px;
    line-height: 1.2;
    a {
      color: #1bace4;

      &:hover {
        color: #fc0;
      }
    }
  }
  .home-news-block-content {
    .date {
      border-left: 2px solid #fc0;
      padding-left: 10px;
    }
  }
  .post {
    .date {
      .fa {
        margin-right: 5px;
      }
    }
    .content {
      position: relative;
    }

  }
}

.button-more {
  color: #fff;
  background-color: #1bace4;
  display: block;
  float: right;
  margin: auto;
  padding: 10px 12px;
  margin-bottom: 0;
  font-size: 14px;
  height: 36px;
  min-width: 200px;
  //max-width: 200px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  &:hover {
    color: #fff;
    background-color: #FC0;
  }
}

.single-post {
  .bottom-contact-info {
    border-top: 1px solid rgba(204, 204, 204, 0.5);
  }
}

.bottom-contact-info {
  text-align: center;
  font-size: 21px;
  padding: 25px 0;
  p {
    margin-bottom: 0;
    text-align: center;
    font-size: 21px;
    a {
      padding-left: 5px;
      color: inherit;
      &:hover {
        color: #fc0;
      }
    }
  }
}

////// graviti form /////

#feedback {
  display: none;
  margin: 50px auto;

}

.modal-content-forms {
  max-width: 575px;
}

.gform_heading {
  h3.gform_title {
    text-align: center;
    font-size: 22px;
    color: #1bace4;
    margin-bottom: 15px;
  }
}

.gform_fields {
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #989898;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #989898;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #989898;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #989898;
  }
  .html-formblock-0,
  .html-formblock-1,
  .html-formblock-2 {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
  }

  .gfield {
    input,
    textarea {
      border-radius: 10px;
      padding: 10px 15px;
      border-color: #1bace4;
      &:focus{
        border-color: #FC0;
      }
    }

  }
  .form-html-block {
    text-align: center;
    font-size: 22px;
    color: #1bace4;
    margin-bottom: 15px;
  }

  ///// HIDE LABEL FORM ///
  .form-ask-name,
  .form-ask-email,
  .form-ask-message {
    label {
      display: none;
    }

  }
  ///// HIDE LABEL FORM ///
  .validation_message {
    color: red;
    margin-bottom: 5px;
    text-align: left;
  }
}
.validation_error {
  color: red;
  text-align: center;
}
.gform_footer {
  .gform_button {
    background: #1bace4;
    margin: auto;
    text-align: center;
    display: block;
    border-radius: 10px;
    min-width: 200px;
    &:hover, &:focus {
      background: #FC0;
    }
  }
}

.gform_confirmation_message{
  border: 2px solid #1bace4;
  border-radius: 10px;
  padding: 15px;
  color: #1bace4;
  font-weight: 600;
}
////// end graviti form /////

#back-to-top {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  position: fixed;
  font-size: 22px;
  color: #ffffff;
  bottom: 40px;
  text-align: center;
  right: 10px;
  border-radius: 50%;
  overflow: hidden;
  background: #ffcc00;
  visibility: hidden;
  transition: background-color .3s, opacity .5s, visibility .5s;
  opacity: 0;
  z-index: 1000;
  &:after {
    content: "\f077";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    font-size: 22px;
    line-height: 37px;
    display: block;
    color: #fff;
  }
  &:hover,
  &:active{
    cursor: pointer;
    opacity: 1;
  }
  &.show{
    opacity: 0.5;
    visibility: visible;}
    &:hover{
      opacity:1;
    }
}

tr.row-1{
  background: #d9edf7;
  //.column-2{
  //  strong{
  //    visibility: visible;
  //  }
  //}
  .column-2,
  .column-6{
    //visibility: hidden;
    cursor: default;
    &:after{
      //display: none;
      opacity: 0;
    }

  }
}
.dataTables_wrapper{
  overflow-x: scroll;
}
.mc4wp-form{
  text-align: center;
  input{
    border-radius: 10px;
    padding: 10px 15px;
    border-color: #1bace4;
  }
  .button{
    background: #1bace4;
    margin: auto;
    text-align: center;
    display: block;
    border-radius: 10px;
    min-width: 200px;
    padding: 12px 15px;
    &:hover{
      background: #FC0;
      border: 1px solid transparent;
    }
  }
}

[type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus, textarea:focus {
  border-color: #FC0;
}
.g-recaptcha > div {
  text-align: center;
  margin: 0 auto 1rem;
}
.fancybox-container .fancybox-caption{
  font-size: 18px;
  text-align: center;
}
.title-head {

  padding: 10px 15px;
  margin-bottom: 15px;
  border-top: 1px solid rgba(204, 204, 204, 0.5);
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
  h1{
    font-size: 32px;
    text-align: center;
    color: #1bace4;
  }
  .sub-title-text p {
    font-size: 16px;
    color:#000;
    &:last-child{
      margin-bottom: 0;
    }
  }
  span.image-under-title {
    display: block;
    width: 100%;
    text-align: center;
    img{

    }
  }

}
.line-section{
  padding: 25px 0;
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
}

.text-block-and-image{


  h3{
    font-size: 21px;
    color:#ffd318;
  }
  p{
    font-size: 16px;
    text-align: justify;
    letter-spacing: 0.06em;
  }
}
.button-box-wrap {

  .link-other {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    line-height: 1.42857143;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #1bace4;
    font-size: 16px;
    .fa{
      margin-right: 5px;
    }
    &:hover{
      color: #fc0;
    }
  }
}
section.content-only-text{
  .title-block{
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }
  //.content-text-box:last-child{
  //  border-bottom: none;
  //}
}
.content-text-box{
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
  padding-bottom: 10px;
  margin-bottom: 20px;
  h4{
    font-size: 21px;
    color: #ffd318;
    font-weight: 700;
    letter-spacing: 0.06em;
  }
  li, p{
    letter-spacing: 0.06em;
    font-size: 16px;
    text-align: justify;
  }

}
.page-template-question{

  .text-with-img{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    padding: 0;
    .text-block{
      font-size: 19px;
      text-align: center;
      color: #fc0;
      h1, h2, h3, h4, h5, h6{
        font-size: 21px;
      }
    }
  }
  .question-section{
    .accordion-item{
      margin-bottom: 5px;
      border-radius: 4px;
      border-bottom: 1px solid #e6e6e6;
      &:last-child:not(.is-active) > .accordion-title{
        border-bottom: 0;
      }
      .accordion-title{
        font-size: 18px;
        color: #1bace4;
        padding: 10px 30px;
        &:hover, &:focus{
          background: transparent;
        }

        &:before{
          transform: rotate(90deg);
        }
        &:after{
          transform: rotate(0deg);
        }
        &:before,
        &:after{
          //content: '\f107';
          //font-family: FontAwesome;
          //font-size: 20px;
          //color: #000;
          //right: auto;
          //left: 0.5rem;
          content: '';
          width: 15px;
          height: 2px;
          position: absolute;
          display: block;
          left: 7px;
          right: initial;
          margin: auto;
          top: 50%;
          background: #1bace4;
          transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
        }
      }
      .accordion-content{
        font-size: 16px;
        letter-spacing: 0.06em;
        line-height: 1.5em;

      }
    }
    .is-active{
      .accordion-title{
        &:before{
          transform: rotate(360deg);
        }
      }
    }
  }
}

///// training ////
.title-head-training{
  padding: 10px 0;
  border-top: 1px solid rgba(204, 204, 204, 0.5);
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.page-title-4 {
  text-align: center;
  color: #1bace4;
  &, p {
    font-size: 21px;
    line-height: 1.1;
    font-weight: 700;
    margin: 0;
    strong, b {
      color: #ffcc00;
    }
  }
}
.page-title-3 {
  float: left;
  text-align: center;
  color: #1bace4;
  width: 83%;

   &, p {
    font-size: 21px;
    line-height: 1.1;
    font-weight: 700;
    margin: 0;
    strong, b {
      color: #ffcc00;
    }
  }
}
.image-under-title-3{
  float: right;
  display: block;
  width: 17%;
}
h3.header-training-section {
  color: #1bace4;
  //font-size: 16px;
  text-align: center;
  font-weight: 700;
  line-height: 1;
  margin: 10px 0;
}

.alignleft{
  float: left;
  margin: 5px 20px 20px 0;
}
.training-text-section {
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(204,204,204,.5);
}

.content-box-training-section {
  h2{
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    line-height: 1.2;
    margin: 10px 0;
  }
  h3{
    font-size: 16px;
    color: #1bace4;
    line-height: 1.2;
  }
  h4{
    font-size: 16px;
    font-weight: 500;
    line-height: 1.3;
    color:#ff0000;
  }
  h5{
    font-size: 31px;
    line-height: 1.3;
  }
 &,p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  color: #000;
  text-align: justify;
}
  a{
    font-size: 18px;
    color: #1bace4;
  }
}
h3.sub-trraining-section {
  font-size: 16px;
  text-align: right;
  color: #444;
  line-height: 1;
  font-style: italic;
}

//////////// GALERY //////////
//////////////////////////////

.description-gallery-header{
  p{
    font-size: 16px;
    font-weight: 400;
    line-height: 1.7;
    color: #000;
    text-align: justify;
  }
  a{
    color: #1bace4;
    font-size: 18px;
  }
  h3{
    font-size: 16px;
    color: #1bace4;
    line-height: 1.1;
  }
}
.gallery-block{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.vid-gall-hide{
  display: none;
  width: 100%;
}

.video-gallery-page {
  .video-link {
    display: block;
    position: relative;
    max-height: 290px;
    overflow: hidden;
    &:hover {
      img {
        transform: scale(1.1);
      }
      &:after {
        opacity: 1;
        animation: pulse-border 1500ms ease-out infinite;
      }
    }
    &:before {
      content: '\f04b';
      font-size: 50px;
      line-height: 80px;
      color: #fc0;
      padding-left: 12px;
      border: 2px solid #ffcc00;
      text-align: center;
      font-family: FontAwesome;
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 80px;
      height: 80px;
      background: rgba(27, 172, 228, 0);
      border-radius: 50%;
    }
    .bg-img-vide {
      display: block;
      position: absolute;
      animation: relative;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
    }
    &:after {
      opacity: 0;
      content: "";
      position: absolute;
      z-index: 0;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 90px;
      height: 90px;
      background: #1bace4;
      border-radius: 50%;

    }
    img {
      display: block;
      margin: auto;
      width: 100%;
      transform: scale(1);
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
    }

  }
  .flex-video{
    //max-height: 280px;
    padding-bottom: 56.25%;
    margin-bottom: 0;
  }
  .widescreen{
    margin-bottom: 0;
  }
  .video-block{
    margin-bottom: 20px;
  }
}

.img-block{
  position: relative;
  width: 33%;
  height: 200px;
  padding: 5px;
  transition: all 0.6s ease;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  a{
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    //margin: 5px;
    img{
      position: absolute;
      width: 100%;
      height: auto;
      display: block;
      top: 50%;
      Left: 50%;
      transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }
  .img-hover-effect {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    display: none;
    opacity: 0;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;

    .fa {
      font-size: 20px;
      color: #fff;
    }
  }
  &:hover{
    .img-hover-effect{
      display: block;
      opacity: 1;
      -webkit-transition: all 0.6s ease;
      -moz-transition: all 0.6s ease;
      -o-transition: all 0.6s ease;
      transition: all 0.6s ease;
    }
  }
  .gal-hover-wrap {
    position: relative;
    text-align: center;
    top: 50%;
    left: 50%;
    padding: 20px;
    transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    p{
      font-size: 16px;
      line-height: 1.2;
      color: #fff;
      margin: 0 0 5px 0;
      max-height: 130px;
      overflow: hidden;
      text-align: center;
    }
  }
}

.title-gallery {
  font-size: 20px;
  line-height: 1.1;
  color: #1bace4;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
  padding-top: 15px;
}

.gallery-main {
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.7;
    color: #000;
    text-align: justify;

  }
  h2{
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    line-height: 1;
    margin: 10px 0;
    color: #1bace4;
  }
  h3{
    font-size: 16px;
    color: #1bace4;
    line-height: 1;
  }
  h4{
    font-size: 16px;
    font-weight: 500;
    line-height: 1.3;
    color: #1bace4;
  }

  a{
    font-size: 18px;
    color: #1bace4;
  }
}

.title-head-registry {
  padding: 10px 0;
  border-top: 1px solid rgba(204, 204, 204, 0.5);
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
  span.image-under-title {
    display: block;
    text-align: center;
    padding: 10px;

  }
  h1.page-title-registry {
    text-align: center;
    font-size: 24px;
    color: #1bace4;
    font-weight: 500;
    text-transform: uppercase;
  }
  .image-under-title-3{
    width:100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .page-title-3{
    width: 100%;
  }
}

.number_polygraph-section{
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
  text-align: center ;
  .number_polygraph{
    color:#ffd318;
    font-size: 21px;
    padding: 10px;
  }
}
.modal-window-box{
  text-align: center;
}
h2.header-registry-section {
  text-align: center;
  font-size: 24px;
  color: #1bace4;
  font-weight: 500;
  text-transform: uppercase;
}

.tablepress {
  thead tr {
    th.sorting {
      text-align: center;
    }
    th.sorting_asc,
    th.sorting_desc {
      text-align: center;
    }
  }
  tbody td{
    text-align: center;
    vertical-align: middle;
    img{
      margin: auto;
      display: block;
      width: auto;
      max-width: 100%;
      float: none;
      max-height: 180px;

    }
  }
  td,
  th{
    padding: 20px 8px;
  }
}


///// predstav /////
.page-template-representative{

  section{
    padding: 25px 0;
    border-bottom: 1px solid rgba(204,204,204,.5);
  }
}
.map-block{
  .info-region{
    display: none;

  }

  .info-region.open:last-child{
    border-bottom: none;
  }
  .open{
    display: block;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);

  }
  .info-region-id-box{
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
    &:last-child{
      border: none;
    }
  }
  .caption{
    font-size: 18px;
    font-weight: 700;
  }
  .leadership{
    font-size: 16px;
  }
  .link{
    font-size: 16px;
    color: #1bace4;
    &:hover{
      color: #fc0;
    }
  }
  .description{
    font-size: 16px;
    letter-spacing: 0.06em;
    line-height: 1.5;
  }
}
////// association /////
.section-line-top hr {
  margin-top: 0;
}

.page-template-testing{
  section.image-section{
    text-align: center;
  }
  section.quick-order-form {
    padding: 50px 0 50px;
  }
}
.page-template-polygraps{
  .page-title-head{
    margin-bottom: 15px
  }
}

.page-template-association, .page-template-testing, .page-template-polygraps, .single-post{


  .text-section{
    h3{
      font-size: 18px;
    }
    .text-block{
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.06em;
    }
    blockquote {
      background-color: #f5f5f5;
      border: 1px solid #ccc;
      padding-bottom: 0.5625rem;
      color:#000;
      p {
        margin: 0;
        color:#000;
        line-height: 1.2;
      }
      a{
        font-size: 18px;
      }
    }
    a{
      color:#1bace4;
      &:hover{
        color:#ffcc00;
      }
    }
  }
  .title-section-h3-tts{
    p:last-child{
      margin-bottom: 0;
    }
    h3{
      font-size: 1.9375rem;
    }
  }
  .block-with-accordion{

    .accordion-item{
      margin-bottom: 5px;
      border-radius: 4px;
      border-bottom: 1px solid #e6e6e6;
      &:last-child:not(.is-active) > .accordion-title{
        border-bottom: 0;
      }
      .accordion-title{
        font-size: 16px;
        color: #1bace4;
        padding: 12px 30px;
        text-align: left;
        height: 40px;
        &:hover, &:focus{
          background: transparent;
        }
        &:before{
          transform: rotate(90deg);
        }
        &:after{
          transform: rotate(0deg);
        }
        &:before,
        &:after{
          content: '';
          width: 15px;
          height: 2px;
          position: absolute;
          display: block;
          left: 7px;
          right: initial;
          margin: auto;
          top: 50%;
          background: #1bace4;
          transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
        }
      }
      .accordion-content{
        font-size: 16px;
        letter-spacing: 0.06em;
        line-height: 1.5em;
        text-align: left;
        ul{
          margin-bottom: 1rem;
        }
      }
    }
    .is-active{
      .accordion-title{
        &:before{
          transform: rotate(360deg);
        }
      }
    }
  }
  .table-section{
    .left-column{
      color: #000;
      font-size: 18px;
      text-align: left;
    }
    .right-column{
      font-size: 18px;
      text-align: center;
      color: #000;
    }
  }
  .gallery{
    list-style-type: none;
    li{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: stretch;
    }
  }
  .partners-item{
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    img{
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
    }
    &:hover{
      img{

        transform: scale(1.2)
      }
    }
  }
  .link-other{
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    line-height: 1.42857143;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #1bace4;
    font-size: 16px;
    &:hover{
      color: #fc0;
    }
    .fa{
      margin-right: 5px;
    }
  }
  .title-block{
    font-size: 1.9375rem;
    font-weight: 600;
    color: #1bace4;
    text-align: center;
    padding: 40px 0 40px;
  }
  .title-block.title-size-18,
  .title-size-18{
    font-size: 18px;
    font-weight: 600;
    color: #1bace4;
  }
  .title-block.title-size-22,
  .title-size-22{
    font-size: 22px;
    font-weight: 600;
    color: #1bace4;
  }
  .title-block.title-size-32,
  .title-size-32{
    font-size: 32px;
    font-weight: 600;
    color: #1bace4;
  }
  .expert-item{
    padding: 25px 0;
    border-bottom: 1px solid rgba(204,204,204,.5);
    &:first-child{
      padding-top: 0;
    }
    &:last-child{
      border-bottom:none;
    }
    .full-name{
      font-weight: 700;
      color: #000;
      font-size: 21px;
    }
    .position{
      font-weight: 700;
      color: #000;
      font-size: 18px;
    }
    .description{
      font-size: 16px;
      letter-spacing: 0.06em;
      line-height: 1.5;
    }
    .expert-item-image {
      height: 230px;
      overflow: hidden;
      position: relative;
      img {
        display: block;
        margin: auto;
        height: 230px;
        width: auto;
      }
    }
  }
  .wp-caption{
    background: transparent;
    border: 1px solid rgba(204,204,204,.5);
    margin-bottom: 10px;
  }
  .polygraph-block{
    .title-block{
      text-align: center;
      color: #1bace4;
      font-size: 27px;
      font-weight: 700;
    }
    .text-block{
      font-size: 16px;
      letter-spacing: 0.06em;
      line-height: 1.5;
    }

    .polygraph-info{
      padding:0;
      //border-top: 1px solid rgba(204,204,204,.5);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: center;
      .caption-polygraph{
        font-size: 16px;
        text-align: left;
        color: #1bace4;
        border-bottom: 1px solid rgba(204, 204, 204, 0.5);
        margin-bottom: 15px;
        padding-bottom: 5px;
        p:last-child{
          margin-bottom: 0;
        }
      }
      ul{
        list-style-type: none;
        li{
          margin-bottom: 10px;
        }
      }
    }
  }
  .video-section{
    .title-block{
      text-align: center;
      color: #fc0;
      font-size: 27px;
      font-weight: 700;
    }
  }

  .title-images-testing {
    text-align: center;
  }

  .polygraph-info-box{
    padding: 0 15px;
    .description{
      ul{
        font-size: 14px;
        text-align: left;
        margin: 0;
        li{
          padding-left: 20px;
          position: relative;
          &:before{
            content: '\f046';
            font-family: FontAwesome;
            position: absolute;
            display: block;
            top: 2px;
            left: 0;
            color: #1bace4;
          }
        }
      }
    }
  }
  .wrapper-polygraph-info {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.35);
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    .description {
      padding-left: 10px;
      p{
        margin-bottom: 10px;
      }
    }

  }

  .rental-polygraph-img {
    text-align: center;
  }
  span.decor-pad {
    display: block;
    padding-bottom: 40px;
  }
  .question-answer{
    padding-top: 25px;
    h3.title-block-question-alb {
      color: #1cace4;
      text-align: center;
      font-size: 1.9375rem;
      font-weight: bold;
      margin-bottom: 40px;
    }
    .question-answer-right-block,
    .ans-title-block{
      text-align: center;
    }
    .title-block{
      margin-bottom: 1rem;
    }
    .question-answer-block{
      list-style-type: decimal;
      font-weight: 600;
    }
    .question-block{
      display: block;
    }
    .answer-block{
      display: block;
      color: #1bace4;
      font-weight: normal
    }
    .answer-yes {
      background: rgba(27, 172, 228, 0.2);
      border-radius: 5px;
      border: 1px solid #1bace4;
      vertical-align: middle;
      padding: 10px 0;
      margin-bottom: 1rem;
      p{
        margin-bottom: 0;
      }
    }
  }

}
.title-head-testing{
  padding: 10px 0;
  border-top: 1px solid rgba(204, 204, 204, 0.5);
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
  //display: flex;
  //flex-direction: row;
  //flex-wrap: nowrap;
  //justify-content: center;
  //align-items: center;
  //align-content: center;
  span.image-under-title {
    text-align: center;
    display: block;
    img{
      width:360px;
    }
  }
  .page-title-testing {
    font-size: 34px;
    font-weight: bold;
    text-align: center;
    color: #1bace4;
    text-transform: uppercase;
  }
}

.title-head-association{
  border-top: 1px solid rgba(204, 204, 204, 0.5);
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.page-title-association {
  font-size: 32px;
  text-align: center;
  padding: 10px 0;
  color: #1bace4;
  margin-bottom: 0;
}
span.image-under-title {
  display: block;
  width: 100%;
  text-align: center;
}
.sub-title-text{
  font-size: 16px;
  color: #000;
}

//////////////////////////////
/////// 2 blocks Image //////
////////////////////////////
.image-2-block {
  .title-section-box{
    text-align: center;
    padding-top: 25px;
  }
  .image-2-block-item {
    text-align: center;
  }
}

//////////////////////////////////
/////// end 2 blocks Image //////
////////////////////////////////
.hr-line-sc{
  padding-top: 25px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
}
section.forensic_examination_section{

  .title-block{
    padding-top: 15px;
  }
  .title-section-box {
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
    margin-bottom: 10px;
  }
}

///////////////  MASS MEDIA - ABOUT US - ///////////
section.section-mass-media-about-us{
  .breadcrumbs{
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
    margin-bottom: 30px;
  }
}

h2.title-mass-media {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
}
.mass-media-box {
  padding-bottom: 20px;
  border-bottom:1px solid rgba(204, 204, 204, 0.5);
  margin-bottom: 20px;
}
a.mass-media-button {
  color: #fff;
  background-color: #1bace4;
  display: block;
  float: right;
  padding: 10px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #1bace4;
  border-radius: 4px;
  &:hover{
    color:#FC0;
  }
}

/////////////// END  MASS MEDIA - ABOUT US - ///////////

.diploms-testing-page{
  .title-section-box{
    text-align: center;
    padding-top: 25px;
  }
}

.fof_page {
  text-align: center;
  padding: 150px 0;
  h1 {
    font-size: 80px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  h2 {
    text-transform: uppercase;
    font-size: 26px;
    margin-bottom: 60px;
  }
  a {
    font-size: 26px;
    color: #FC0;
    &:hover{
      color:#1bace4;
    }
  }
}

.reveal-overlay{
  //overflow: hidden;
  z-index: 99999;
}
//body.is-reveal-open {
//  overflow: auto;
//}
.is-reveal-open .off-canvas-wrapper {
  margin-left: -18px;
}
.video-open{
  iframe{
    display: none;
  }
}
.video-link{
  .youtube-play {
    position: absolute;
    top: 50%;
    Left: 50%;
    transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    width: 68px;
    height: 48px;
    transition: opacity .25s cubic-bezier(0, 0, .2, 1);
    z-index: 3
  }
  &:hover{
    .ytp-large-play-button-bg {
      transition: fill .1s cubic-bezier(.4, 0, 1, 1), fill-opacity .1s cubic-bezier(.4, 0, 1, 1);
      fill: #212121;
      fill-opacity: .8;
      fill: red;
      fill-opacity: 1
    }
  }
}

.dataTables_length {
  display: none;
}
.dataTables_filter {
  float: left;
}
//////////////////////////// SEO Schema.org  /////////////////////////////
.seo_schema_org{
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: .06em;
  padding-top: 30px;
  padding-bottom: 30px;
  h3{
    font-size: 18px;
  }
  .name {
    font-size: 21px;
    font-weight: 600;
    color: #1bace4;
    text-align: center;
    padding-bottom: 15px;
  }
  .foto {
    width: 50%;
    float: left;
    padding-right: 15px;
    img{
      width: 100%;
      height:auto;
    }
  }
  .offers{
    width:50%;
    float: left;
    padding-left: 15px;
  }
  .brand {
    font-weight: 600;
    color: #1bace4;
    font-size: 18px;
  }
  .price {
    color: #1bace4;
    padding: 5px 0;
  }

  .seo-iframe-box{
    overflow-y: scroll;
    height: 400px;
  }
}

//////////////////////////// END  SEO Schema.org  /////////////////////////////

///// Форма быстрого заказа ///////////
section.quick-order-form {
  padding: 25px 0 63px;
}
.quick-form-wrapper-box {
  background: #fc0;
  padding: 19px 38px 21px;
  display: flex;
  align-items: center;
  .gfield_label{
    display: none;
  }
  .quick-form-text {
    width:55%;
    font-size: 24px;
    font-weight: 700;
    color: #3b4952;
    text-align: left;

    p{
      margin: 0;
      line-height: 1.3;
    }
  }
  span.question-icon {
    width:10%;
    height: 60px;
    svg {
      height: 60px;
    }
  }
  .quick-form-box {
    position: relative;
    width: 35%;
  }
  .gform_fields .gfield input{
    width: 90%;
    height: 40px;
    border-radius: 5px;
    padding: 7px 15px;
    border: none;
    margin: 0;
    letter-spacing: 1px;
    &:focus{
      outline: 0;
      box-shadow: 0 0 7px #1779ba;
    }
    &::placeholder{
      color: #0a0a0a;
    }
  }
  .gform_footer{
    position: absolute;
    right: 0;
    top: 0;
    .gform_button {
      &:disabled{
        opacity: 1;
        background: #ccc;
        color: #000;
      }
      min-width: initial;
      height: 40px;
      background: #1bace4;
      font-size: 14px;
      padding: 8px 35px 10px;
      border-radius: 25px;
      border: 2px solid transparent;
      user-select: none;
      &:hover{
        border-color: #fff;
        background:#fc0;
      }
    }
  }
}
input.gform_button.button.disabled{
  background: #ccc;
  color: #3b4952;
  pointer-events: none;
  opacity: 1;
  outline: 0;
}
///// END Форма быстрого заказа ///////////


//// Contact form 7 //////

div.wpcf7 {
  .ajax-loader {
    right: 13px;
    position: absolute;
    top: 11px;
  }
  input,
  textarea,
  select{
    border-radius: 10px;
    padding: 10px 15px;
    border-color: #1bace4;
    &:focus,
    &:focus {
      border-color: #fc0;
    }
  }
  select{
    background-position: right -5px center;
  }
  .wpcf7-radio .wpcf7-list-item {
    display: block;
  }
   .wpcf7-submit {
      background: #1bace4;
      margin: auto;
      text-align: center;
      display: block;
      border-radius: 10px;
      min-width: 200px;
      color: #fefefe;
      box-shadow: none;
      border: none;
     cursor: pointer;
     transition: all 0.3s ease-in-out;
     -o-transition: all 0.3s ease-in-out;
     -moz-transition: all 0.3s ease-in-out;
     -webkit-transition: all 0.3s ease-in-out;
     -ms-transition: all 0.3s ease-in-out;
     &:hover{
       background: #fc0;
     }
   }
  .form-your-yes,
  .form-your-vap-yes{
    input{
        float: left;
        margin: 5px 8px 0 0;

    }
  }
  span.html-box {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    display: block;
  }
  span.form-html-block {
    display: block;
    text-align: center;
    font-size: 22px;
    color: #1bace4;
    margin-top: 15px;
  }
  &::placeholder{
    color: #989898;
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #989898;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #989898;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #989898;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #989898;
  }

}

div.wpcf7-mail-sent-ok{
  border-color: #1bace4;
}

.quick-form-box {
  div.wpcf7 {
    form.wpcf7-form.sent {
      .wpcf7-form-control-wrap input,
      .wpcf7-submit {
        display: none;
      }
    }
    p {
      margin: 0 auto;
    }
    .wpcf7-form-control-wrap input {
      width: 90%;
      height: 40px;
      border-radius: 5px;
      padding: 7px 15px;
      border: none;
      margin: 0;
      letter-spacing: 1px;
      box-shadow: none;
      &:visited,
      &:focus {
        outline: 0;
        box-shadow: 0 0 7px #229fff;
        border: none;
      }

    }

    .wpcf7-submit {
      position: absolute;
      right: 0;
      top: 0;
      min-width: 0;
      height: 40px;
      background: #1bace4;
      color: #fff;
      font-size: 14px;
      padding: 8px 35px 10px;
      border-radius: 25px;
      border: 2px solid transparent;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      outline: none;
      &.disabled {
        background: #ccc;
        color: #3b4952;
        pointer-events: none;
        opacity: 1;
        outline: 0;
      }
      &:hover {
        border-color: #fff;
        color: #3b4952;
        background: #fdda24;
        box-shadow: 0 0 7px #229fff;
      }
    }
  }
}


////////////////////////// contact form
.wpcf7{
  .screen-reader-response {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    margin: 0;
    padding: 0;
    border: 0;
  }
  form {
    .wpcf7-response-output {
      margin: 2em 0.5em 1em;
      padding: 0.2em 1em;
      border: 2px solid #00a0d2; /* Blue */
    }
    &.init .wpcf7-response-output {
      display: none;
    }
    &.sent .wpcf7-response-output {
      border-color: #46b450; /* Green */
    }
    &.failed .wpcf7-response-output,
    &.aborted .wpcf7-response-output {
      border-color: #dc3232; /* Red */
    }
    &.spam .wpcf7-response-output {
      border-color: #f56e28; /* Orange */
    }
    &.invalid .wpcf7-response-output,
    &.unaccepted .wpcf7-response-output {
      border-color: #ffb900; /* Yellow */
    }
  }
}

.wpcf7-form-control-wrap {
  position: relative;
  display: block;
}

.wpcf7-not-valid-tip {
  color: #dc3232;
  font-size: 12px;
  font-weight: 400;
  display: block;
  position: absolute;
  width: 100%;
  top: 2px;
  left: 15px;
}

.use-floating-validation-tip .wpcf7-not-valid-tip {
  position: absolute;
  top: 20%;
  left: 20%;
  z-index: 100;
  border: 1px solid #dc3232;
  background: #fff;
  padding: .2em .8em;
}

span.wpcf7-list-item {
  display: inline-block;
  margin: 0 0 0 1em;
  &::before,
  &::after {
    content: " ";
  }
}
div.wpcf7{
  .ajax-loader {
    visibility: hidden;
    display: inline-block;
    background-image: url('../images/ajax-loader-2.gif');
    width: 16px;
    height: 16px;
    border: none;
    padding: 0;
    margin: 0 0 0 4px;
    vertical-align: middle;
    &.is-active {
      visibility: visible;
    }
  }

  div.ajax-error {
    display: none;
  }
  .placeheld {
    color: #888;
  }
  input[type="file"] {
    cursor: pointer;
    &:disabled {
      cursor: default;
    }
  }
  .wpcf7-submit:disabled {
    cursor: not-allowed;
  }
}

.wpcf7 input[type="url"],
.wpcf7 input[type="email"],
.wpcf7 input[type="tel"] {
  direction: ltr;
}



//// отключение видимости капчи .....
.grecaptcha-badge {
  display: none;
  overflow: hidden;
  visibility: hidden;
}

section.footer-share-it {
  padding-bottom: 50px;
}
.title-share-it p {
  font-size: 28px;
  color: #1bace4;
  line-height: 1.1;
  margin-top: 0;
}
ul.share-it-button-ul {
  padding-top: 15px;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
  text-align: center;
  li{
    width: 25%;
    a{
      font-size: 34px;
      line-height: 34px;
      display: flex;
      width: 60px;
      margin: auto;
      justify-content: center;
      align-items: center;
      align-content: center;
      padding: 10px;
      &:hover{
        opacity: 0.7;
      }
      svg{
        width: 34px;
      }
    }
  }
  .facebook-share{
    color: #4267B2;
  }
  .facebook-messenger-share{
    color: #00C6FF;
    &.visible-mob{
      display: none;
    }
  }
  .telegram-share {
    color: #0088CC;
  }
  .viber-share {
    color: #665CAC;
  }
  .whatsapp-share {
    color: #4AC959;
  }
  .linkedin-share{
    color: #0e76a8;
  }
  .vk-share{
    color: #4680C2;
  }
  .googleplus-share{
    color: #d34836;
  }
  .pinterest-share{
    color: #E60023;
  }
}

@media only screen and (max-width: 1023px){
  ul.share-it-button-ul {
    .facebook-share {
      &.visible-desc {
        display: none;
      }
    }

    .facebook-messenger-share {
      &.visible-mob {
        display: block;
      }
    }
  }
  .title-share-it p {
    font-size: 20px;
  }
}