/******************************************************************

Stylesheet: Mixins & Constants Stylesheet

This is where you can take advantage of Sass' great features:
Mixins & Constants.

******************************************************************/

/*********************
TOOLS
*********************/

// http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
.image-replacement {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}


/*********************
COLORS
*********************/


/*********************
TYPOGRAPHY
*********************/
/*	@font-face {
    	font-family: 'Font Name';
    	src: url('assets/fonts/font-name.eot');
    	src: url('assets/fonts/font-name.eot?#iefix') format('embedded-opentype'),
             url('assets/fonts/font-name.woff') format('woff'),
             url('assets/fonts/font-name.ttf') format('truetype'),
             url('assets/fonts/font-name.svg#font-name') format('svg');
    	font-weight: normal;
    	font-style: normal;
	}
*/

//Use the best ampersand - http://simplebits.com/notebook/2008/08/14/ampersands-2/
span.amp {
  font-family: Baskerville,'Goudy Old Style',Palatino,'Book Antiqua',serif !important;
  font-style: italic;
}

/*********************
CSS3 GRADIENTS.
*********************/

/* @include css-gradient(#dfdfdf,#f8f8f8); */
@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8) {
	background-color: $to;
	background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
	background-image: -webkit-linear-gradient(top, $from, $to);
	background-image: -moz-linear-gradient(top, $from, $to);
	background-image: -o-linear-gradient(top, $from, $to);
	background-image: linear-gradient(to bottom, $from, $to);
}


