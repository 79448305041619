a{
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}
.sc-hr{
  padding-top: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
}
.page-template-home-page {
  .topic-text {
    padding-left: 1px;
    padding-bottom: 5px;
    font-size: 18px;
    line-height: 40px;
    color: #333;
    text-transform: uppercase;
    letter-spacing: .1em;
    text-align: left;
    span {
      font-style: italic;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      top: 2px;
      width: 40px;
      height: 40px;
      color: #fc0;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      border: 2px solid #fc0;
      border-radius: 50%;
      margin-right: 15px;
    }
  }
  //.columns {
  //  padding-left: 0;
  //  padding-right: 0;
  //}
  .title-home {
    font-size: 20px;
    text-align: center;
    margin-bottom: 40px;
    text-transform: uppercase;
    color: #1c98d5;
    padding-left: 15px;
    padding-right: 15px;
  }
  .hr-line{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .slider-section {
    .slide-title {
      font-size: 28px;

      p{
        line-height: 1.2;
      }
      a{
        color: #000;
        text-align: left;
        &:hover{
          color: #1bace4;
        }
      }
    }
    .slider-item{
      position: relative;
      //.topic-text{
      //  font-size: 14px;
      //}
      a.button-more {
        position: absolute;
        bottom: 0;
        right:0.9375rem;
      }
      img{
        margin: auto;
      }
    }
    .description{
      font-size: 18px;
      color: #3a3d3d;
      line-height: 1.5em;
      margin: 0 0 10px;
      padding-bottom: 36px;
    }

    .slick-next{
      right: -100px;
      &:before{
        content: '\f105';
        font-family: FontAwesome;
      }
    }
    .slick-prev{
      left: -100px;
      &:before{
        content: '\f104';
        font-family: FontAwesome;
      }
    }
    .slick-prev, .slick-next {
      position: absolute;
      display: block;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      line-height: 0px;
      font-size: 0px;
      cursor: pointer;
      background: #fc0;
      opacity: 0.5;
      color: transparent;
      top: 40%;
      transform: translate(0, -50%);
      padding: 0;
      border: none;
      outline: none;
      &:hover{
        opacity: 1;

      }
    }
  }
  .number-about-us{
    .column{
      text-align: center;
      //padding: 0;
    }
    .number{
      font-size: 45px;
      line-height: 1;
      color: #1c98d5;
      margin-bottom: 15px;
    }
    .caption-icon {
      font-size: 13px;
      font-weight: 700;
      text-align: center;
      padding: 0 15px;
    }
  }
  .about-us-section{
    .topic-text{
      margin-bottom: 15px;
    }
    .more-link{
      color: #fc0;
      font-size: 16px;
      text-transform: uppercase;
      &:hover{
        color: #1bace4;
      }
    }
    .left-block{
      text-align: center;
    }
    .more-btn{
      float: left;
      margin-top: 20px;
    }
    .video-img-box{
      width:100%;
      height: 247px;
      position: relative;
      overflow: hidden;
      display: block;
      img{
        width:100%;
        position: absolute;
        top: 50%;
        Left: 50%;
        transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
      }

    }

    .caption-block{
      color: #1bace4;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 1.5em;
      text-align: right;
      padding-right: 10px;
      float: left;
    }
    .description-block, .description{
      color: #3a3d3d;
      font-size: 18px;
      text-align: justify;
      float: right;
    }
    .title-block{
      color: #1bace4;
      text-transform: uppercase;
      font-size: 24px;
      text-align: center;
      margin: 20px 0;
    }
  }
  .services-section{
    .services{
      margin-top: 50px;
      text-align: center;
      .item-wrapper {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    .item-service{
      font-weight: 700;
      color: #000;
      font-size: 13px;
      text-align: center;
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      img{
        max-width: 160px;
        transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
      }
      &:hover{
        color: #fc0;
        img{

          transform: scale(1.1)
        }
      }
      span.caption {
        z-index: 1;
        display: block;
        position: relative;
      }
    }
  }
  .partners-block{
    .pwh-b{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
    .partners-item{
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;

      &:hover{
        img{

          transform: scale(1.2)
        }
      }
      img{
        transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
      }
    }
  }
  .news-section{
    .right-sidebar{
      .topic-text{
        margin-bottom: 15px;
      }
    }
    .news-block{
      .button-more{
        margin-right: 15px;
      }
    }
    .button-more{
      float: right;
    }
    .home-news-block-img{
      a{
      display: block;
      position: relative;
      height: 250px;
      overflow: hidden;
      img{
        height: 100%;
        width: auto;
        max-width: none;
        text-align: center;
        margin: auto;
        display: block;
        position: absolute;
        top: 50%;
        Left: 50%;
        transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
      }
      }
    }
  }
  .news-block{
    text-align: center;
    //padding-right:  20px;
    .post{
      text-align: left;
      margin-bottom: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid rgba(204, 204, 204, 0.5);

      height: 280px;
      width: 100%;
      overflow: hidden;
      &:last-child{
        border-bottom: none;
        //margin-bottom: 0;
      }
    }
    .news-block-title{
      margin-bottom: 15px;
    }
  }

  .video-block{
    //margin-bottom: 15px;
    height: 280px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 25px;
    padding-bottom: 25px;
    //border-bottom: 1px solid rgba(204, 204, 204, 0.5);
    &:last-child{
      border-bottom: none;
    }
    &:hover{
      img{
        //transform: scale(1.1);

      }
    }

    .video-link{
      display: block;
      position: relative;
      //max-height: 250px;
      width:350px;
      height: 226px;
      overflow: hidden;
      &:hover{
        img{
          //transform: scale(1.2);
          transform: translate(-50%, -50%) scale(1.2);
          -o-transform: translate(-50%, -50%) scale(1.2);
          -moz-transform: translate(-50%, -50%) scale(1.2);
          -webkit-transform: translate(-50%, -50%) scale(1.2);
          -ms-transform: translate(-50%, -50%) scale(1.2);
        }
        &:after{
          opacity: 1;
          animation: pulse-border 1500ms ease-out infinite;
        }
      }
      &:before{
        content: '\f04b';
        font-size: 50px;
        line-height: 80px;
        color: #fc0;
        padding-left: 12px;
        border: 2px solid #ffcc00;
        text-align: center;
        font-family: FontAwesome;
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        width: 80px;
        height: 80px;
        background: rgba(27, 172, 228, 0);
        border-radius: 50%;
      }
      .bg-img-vide{
        display: block;
        position: absolute;
        animation: relative;
        left: 0;
        top: 0;
        //background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
      }
      &:after{
        opacity: 0;
        content: "";
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        width: 90px;
        height: 90px;
        background: #1bace4;
        border-radius: 50%;

      }
      img{
        display: block;
        margin: auto;
        width: 100%;
        //transform: scale(1);
        transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        position: absolute;
        top: 50%;
        Left: 50%;
        transform: translate(-50%, -50%) scale(1);
        -o-transform: translate(-50%, -50%) scale(1);
        -moz-transform: translate(-50%, -50%) scale(1);
        -webkit-transform: translate(-50%, -50%) scale(1);
        -ms-transform: translate(-50%, -50%) scale(1);
      }

    }
    iframe{
      display: none;
      width:100%;
    }
    .video-title {
      display: block;
      width: 100%;
      font-size: 14px;
      text-align: left;
      margin-top: 5px;
      color: #1bace4;
    }
  }
  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }
}
//////////////////////////////////////////////////////////////
/////////////// HOME PAGE  MASS MEDIA - ABOUT US - ///////////
section.mass-media-home-page {
  .slider-item.home-mass-media{

    padding: 15px;
  }
  .shadow{
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.35);
    //height:335px;
    padding: 0;
  }
  .slick-slide > div{
    //padding:15px;
    //border-right:5px;
  }
  .wrapper-mass-media{
    text-align: center;
  }
  .image-wrapper-mass-media {
    margin-top: 15px;
    margin-bottom: 15px;
    height:150px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    img {
      margin: auto;
      max-height: 150px;
      width: auto;
    }
  }
  .mass-media-name {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;
    border-top: 1px solid rgba(204, 204, 204, 0.5);
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
    padding: 10px 0;
    h3 {
      font-size: 16px;
      color: #1bace4;
      font-weight: 600;
      text-align: left;
      margin: 0;
    }
  }
  .btn-all-mass-media{
    text-align: center;
    .button-more{
      margin-top: 15px;
      float: none;
      display: inline-block;
    }
  }
  a.mass-media-button {
    float: none;
    //width: 50%;
    display: inline-block;
    margin: 0 auto 15px;
  }
}
/////// Section testimonials  ///////
.testimonials-centered {
  width: 90%;
  margin: auto;
}
.slider-testtimonial{
  padding-top: 25px;
  .slick-arrow{
    height:40px;
    &:before{
      font-size:40px;
      color: #1bace4;
    }
  }
}
.image-wrapper-testimonials {
  text-align: center;
  img{
    margin: auto;
  }
}
span.testimonial-name {
  font-size: 18px;
  font-weight: 600;
  display: block;
  color: #1bace4;

}
span.testimonials-position-name {
  color: #ffcc00;
  font-style: italic;
  padding-bottom: 10px;
  display: block;
}

///// section diploms image ///////
section.diploms-home-page {
  height: 100vh;
  //max-height: 900px;
}
.diploms-image-box {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.slider-diploms .slick-arrow {
  height: 40px;
  &:before{
    font-size: 40px;
    color: #1bace4;
  }
}
.diplom-image-block{
  text-align: center;
  a{
    display: inline-block;
    position: relative;
    outline: none;

    img{
      margin: auto;
      max-height: 800px;
    }
    span.diplom-hover-block {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      font-size: 22px;
      text-align: center;
      opacity:0;
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      &:before{
        content: "\f00e";
        display: block;
        position: absolute;
        font: normal normal normal 22px/1 FontAwesome;
        top: 50%;
        Left: 50%;
        transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
      }
    }
    &:hover{
      span.diplom-hover-block {
        opacity: 1;
      }
    }
  }
}
.ui-widget {
  background: #fff;
  border: 1px solid #1bace4;
  padding: 10px;
  display: none;
}
